import { enFirst } from './enfirst.js';
import { jpFirst } from './jpFirst.js';
import { kr20130507 } from './kr20130507.js';
import { kr20150415 } from './kr20150415.js';
import { kr20170612 } from './kr20170612.js';
import { kr20200114 } from './kr20200114.js';
import { kr20201030 } from './kr20201030.js';
import { kr20201106 } from './kr20201106.js';
import { kr20220711 } from './kr20220711.js';
import { kr20220718 } from './kr20220718.js';
import { kr20230118 } from './kr20230118.js';
import { kr20230125 } from './kr20230125.js';
import { kr20240125 } from './kr20240125.js';
import { kr20240904 } from './kr20240904.js';
import { kr20240911 } from './kr20240911.js';
import { nbox20231018 } from './nbox.kr20231018.js';
import { ness_terms, ness_terms_popup, nessKr20231219 } from './ness.kr20231219.js';

export const TERMS_LANG_LIST = {
  kr: {
    latest: kr20240911,
    20240911: kr20240911,
    20240904: kr20240904,
    20240125: kr20240125,
    20230125: kr20230125,
    20230118: kr20230118,
    20220718: kr20220718,
    20220711: kr20220711,
    20201106: kr20201106,
    20200114: kr20200114,
    20201030: kr20201030,
    20170612: kr20170612,
    20150415: kr20150415,
    20130507: kr20130507,
  },
  en: {
    latest: enFirst,
  },
  jp: {
    latest: jpFirst,
  },
};

const TERMS_NBOX_LANG_LIST = {
  kr: {
    latest: nbox20231018,
  },
  en: {
    latest: nbox20231018,
  },
  jp: {
    latest: nbox20231018,
  },
};

const TERMS_NESS_LANG_LIST = {
  kr: {
    latest: () => ness_terms_popup(nessKr20231219()),
  },
  en: {
    latest: () => ness_terms_popup(nessKr20231219()),
  },
  jp: {
    latest: () => ness_terms_popup(nessKr20231219()),
  },
};

const TERMS_PAGE_NESS_LANG_LIST = {
  kr: {
    latest: () => ness_terms(nessKr20231219()),
  },
  en: {
    latest: () => ness_terms(nessKr20231219()),
  },
  jp: {
    latest: () => ness_terms(nessKr20231219()),
  },
};

export const termsHtml = ({ date = 'latest', lang }) => {
  return (TERMS_LANG_LIST[lang][date] || TERMS_LANG_LIST[lang || T.lang].latest)();
};

export const termsNBoxHtml = ({ lang }) => {
  return TERMS_NBOX_LANG_LIST[lang || T.lang].latest();
};

export const termsNessHtml = ({ lang }) => {
  return TERMS_NESS_LANG_LIST[lang || T.lang].latest();
};

export const termsPageNessHtml = ({ lang }) => {
  return TERMS_PAGE_NESS_LANG_LIST[lang || T.lang].latest();
};
